<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <el-row type="flex" justify="end">
        <el-col :span="8" class="lightgreen-box">
          <el-input
            autocomplete="off"
            placeholder="可输入名字或手机进行模糊搜索"
            v-model="searchphone"
            style="width: 300px;"
          ></el-input>
          <el-button class="ml20" type="primary" @click="loadItems()">点击搜索</el-button>
          <el-button type="info" @click="resetloadItems()">重置</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table
      :data="items"
      style="width: 100%; margin-bottom: 20px"
      row-key="_id"
      border
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="userId" label="会员ID"></el-table-column>
      <el-table-column label="默认用户名">
        <template slot-scope="scope">
          <el-popover placement="top-start" title="" trigger="hover">
            <img
              :src="scope.row.avatar"
              alt=""
              style="width: 150px; height: 150px"
            />
            <img
              slot="reference"
              :src="scope.row.avatar"
              style="width: 30px; height: 30px"
            />
          </el-popover>
          {{ scope.row.nickname }}
        </template>
      </el-table-column>
      <el-table-column prop="realname" label="真实姓名"></el-table-column>
      <el-table-column prop="gender" label="性别">
        <template slot-scope="scope">
          {{scope.row.gender === 1 ? "男": "女"}}
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="手机"></el-table-column>
      <el-table-column prop="firsttime" label="首次登录" :formatter="formatterTime"></el-table-column>
      <el-table-column prop="recentlytime" label="最近登录" :formatter="formatterTime"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="goToUserDetails(scope.row._id,scope.row.openid)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @current-change="handlePageChange"
      @size-change="handlePageSizeChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 20, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-card>
</template>
<script>
import { VueEditor } from "vue2-editor";

export default {
  components: { VueEditor },
  data() {
    return {
      formLabelWidth: "120px",
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页数据量
      total: 0, // 总数据量
      items: [],
      searchphone:""
    };
  },
  methods: {
    resetloadItems(){
      this.searchphone =''
      this.loadItems()
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.loadItems();
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.loadItems();
    },
    loadItems() {
      const data = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        searchphone: this.searchphone,
      };
      this.$api.wxuser.searchphoneoruser(data).then((res) => {
        this.currentPage = res.data.pagination.currentPage;
        this.pageSize = res.data.pagination.pageSize;
        this.total = res.data.pagination.total;
        this.items = res.data.data;
      });
    },
    formatterTime(row, column) {
      let data = row[column.property];
      return /\d{4}-\d{1,2}-\d{1,2}/g.exec(data);
    },
    goToUserDetails(id,openid) {
      this.$router.push({ name: 'wxuserview', query: { id: id ,openid:openid} })
    },
  },
  created() {
    this.loadItems();
  },
};
</script>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.el-row {
  margin-bottom: 20px;
}
.el-row :last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.logo {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.ml20 {
  margin-left: 20px;
}
.box-card {
  width: 100%;
}
</style>
